import React, { CSSProperties } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Link, useIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";

import { graphql, useStaticQuery } from "gatsby";
import { commonStyles } from "./styles";
import Button from "./Button";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import AnimatedText from "../AnimatedText";
import deepmerge from "deepmerge";

const SCALE = 0.8;

const useStyles = makeStyles((theme: Theme) => {
  const common = commonStyles(theme);
  return createStyles({
    body: deepmerge(common.body, {
      paddingTop: 200,
      [theme.breakpoints.up(screenWidth.desktop)]: {
        paddingTop: 100,
      },
    }),
    container: deepmerge(common.container, {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        alignItems: "flex-end",
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
      },
    } as CSSProperties),
    content: {
      width: 625,
      textAlign: "left",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        width: "auto",
        paddingLeft: 40,
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        paddingLeft: 0,
        marginTop: 24,
      },
    },
    deck: {
      position: "relative",
      minWidth: 408,
      maxWidth: 408,
      [theme.breakpoints.up(screenWidth.xs)]: {
        minWidth: 320,
        maxWidth: 320,
      },
      [theme.breakpoints.down(screenWidth.xs)]: {
        minWidth: "100%",
        maxWidth: "100%",
        height: "auto",
      },
    },
    image: {
      width: 408,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        maxWidth: "100%",
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down(screenWidth.xs)]: {
        maxWidth: "calc(100% + 30px)",
        width: "calc(100% + 30px)",
      },
    },
    // 애니메이션 키프레임 정의
    "@keyframes fadeOut0": {
      "0%": {
        opacity: 1,
        marginLeft: 0,
      },
      "16.6667%": {
        opacity: 1,
        marginLeft: 0,
      },
      "33.3333%": {
        opacity: 0,
        marginLeft: -500,
      },
      "50%": {
        opacity: 0,
        marginLeft: -500,
      },
      "100%": {
        opacity: 0,
        marginLeft: 0,
      },
    },
    "@keyframes fadeOut1": {
      "0%": {
        opacity: 1,
        marginLeft: 0,
      },
      "50%": {
        opacity: 1,
        marginLeft: 0,
      },
      "66.6667%": {
        opacity: 0,
        marginLeft: -500,
      },
      "100%": {
        opacity: 0,
        marginLeft: -500,
      },
    },
    "@keyframes fadeOut2": {
      "0%": {
        opacity: 1,
        marginLeft: 0,
      },
      "83.3333%": {
        opacity: 1,
        marginLeft: 0,
      },
      "100%": {
        opacity: 0,
        marginLeft: -500,
      },
    },
    imageAnim0: {
      animation: "$fadeOut0 6s ease-out infinite",
      animationDelay: "1s",
    },
    imageAnim1: {
      animation: "$fadeOut1 6s ease-out infinite",
      animationDelay: "1s",
    },
    imageAnim2: {
      animation: "$fadeOut2 6s ease-out infinite",
      animationDelay: "1s",
    },
    "@keyframes scaleOut": {
      "0%": {
        transform: `scale(${SCALE})`,
      },
      "10%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1)",
      },
      "51%": {
        transform: `scale(${SCALE})`,
      },
      "100%": {
        transform: `scale(${SCALE})`,
      },
    },
    "@keyframes scaleOutBg": {
      "0%": {
        transform: `scale(${SCALE})`,
      },
      "10%": {
        transform: "scale(1)",
      },
      "30%": {
        transform: "scale(1)",
      },
      "31%": {
        transform: `scale(${SCALE})`,
      },
      "100%": {
        transform: `scale(${SCALE})`,
      },
    },
    dBg: {
      transform: `scale(${SCALE})`,
      animationDelay: "6s",
      animationName: "$scaleOutBg",
      animationDuration: "6s",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.22, 1.2)",
      animationIterationCount: "infinite",
      [theme.breakpoints.down(screenWidth.desktop)]: {
        marginRight: -20,
        maxWidth: "calc(100% + 30px)",
        width: "calc(100% + 30px)",
        height: "auto",
      },
    },
    d0: {
      transform: `scale(${SCALE})`,
      left: 0,
      top: 0,
      position: "absolute",
      animationName: "$scaleOut",
      animationDuration: "6s",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.22, 1.2)",
      animationIterationCount: "infinite",
      animationDelay: `${0 * 2 - 0.05}s`,
      [theme.breakpoints.down(screenWidth.desktop)]: {
        marginRight: -20,
        maxWidth: "calc(100% + 30px)",
        width: "calc(100% + 30px)",
        height: "auto",
      },
    },
    d1: {
      transform: `scale(${SCALE})`,
      left: 0,
      top: 0,
      position: "absolute",
      animationName: "$scaleOut",
      animationDuration: "6s",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.22, 1.2)",
      animationIterationCount: "infinite",
      animationDelay: `${1 * 2 - 0.05}s`,
      [theme.breakpoints.down(screenWidth.desktop)]: {
        marginRight: -20,
        maxWidth: "calc(100% + 30px)",
        width: "calc(100% + 30px)",
        height: "auto",
      },
    },
    d2: {
      transform: `scale(${SCALE})`,
      left: 0,
      top: 0,
      position: "absolute",
      animationName: "$scaleOut",
      animationDuration: "6s",
      animationTimingFunction: "cubic-bezier(0.175, 0.885, 0.22, 1.2)",
      animationIterationCount: "infinite",
      animationDelay: `${2 * 2 - 0.05}s`,
      [theme.breakpoints.down(screenWidth.desktop)]: {
        marginRight: -20,
        maxWidth: "calc(100% + 30px)",
        width: "calc(100% + 30px)",
        height: "auto",
      },
    },
    title: common.title,
    subtitle: common.subtitle,
    description: common.description,
    buttonWrap: common.buttonWrap,
  });
});

const Match: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      match1: file(relativePath: { eq: "product/match-1@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      match2: file(relativePath: { eq: "product/match-2@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      match3: file(relativePath: { eq: "product/match-3@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const match1 = data.match1.childImageSharp.fluid;
  const match2 = data.match2.childImageSharp.fluid;
  const match3 = data.match3.childImageSharp.fluid;

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <div className={classes.deck}>
          <div className={classes.dBg}>
            <Img
              fluid={match1}
              alt="Azarlive match image 1"
              className={`${classes.image}`}
            />
          </div>
          <div className={classes.d2}>
            <Img
              fluid={match3}
              alt="Azarlive match image 3"
              className={`${classes.image} ${classes.imageAnim2}`}
            />
          </div>
          <div className={classes.d1}>
            <Img
              fluid={match2}
              alt="Azarlive match image 2"
              className={`${classes.image} ${classes.imageAnim1}`}
            />
          </div>
          <div className={classes.d0}>
            <Img
              fluid={match1}
              alt="Azarlive match image 1"
              className={`${classes.image} ${classes.imageAnim0}`}
            />
          </div>
        </div>
        <div className={classes.content}>
          <AnimatedText className={classes.title}>
            {intl.formatMessage({ id: "new_AZAR" })}
          </AnimatedText>
          <AnimatedText delay={200} className={classes.subtitle}>
            {intl.formatMessage({ id: "main_short_description" })}
          </AnimatedText>
          <AnimatedText delay={400} className={classes.description}>
            {intl.formatMessage({ id: "main_long_description" })}
          </AnimatedText>
          <AnimatedText delay={600} className={classes.buttonWrap}>
            <a href="https://azarlive.com/" target="_blank" rel="noreferrer">
              <Button color="secondary">
                {intl.formatMessage({ id: "product_video_chat" })}
              </Button>
            </a>
          </AnimatedText>
        </div>
      </div>
    </div>
  );
};

export default Match;
