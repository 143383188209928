import React, { CSSProperties, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import YouTube from "react-youtube";
import { useIntl } from "gatsby-plugin-intl";
import AnimatedText from "../AnimatedText";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import { commonStyles } from "./styles";
import deepmerge from "deepmerge";

// 스타일 생성
const useStyles = makeStyles<Theme>(theme => {
  const common = commonStyles(theme);
  return createStyles({
    body: deepmerge(common.body, {
      paddingTop: 140,
      marginBottom: 140,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        paddingTop: 100,
        marginBottom: 40,
      },
    }),
    container: deepmerge(common.container, {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      alignItems: "center",
      padding: 0,
      "& iframe": {
        borderRadius: 24,
      },
      [theme.breakpoints.down(screenWidth.tablet)]: {
        padding: 0,
      },
      [theme.breakpoints.down(screenWidth.productYoutubeMinSize)]: {
        "& iframe": {
          borderRadius: 0,
        },
      },
    } as CSSProperties),
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: screenWidth.productYoutubeMinSize,
      padding: "80px 40px 0px 40px",
      color: "black",
      [theme.breakpoints.down(screenWidth.productYoutubeMinSize)]: {
        width: "auto",
      },
      [theme.breakpoints.down(screenWidth.tablet)]: {
        paddingTop: 60,
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        paddingTop: 40,
      },
    },
    subtitle: deepmerge(common.subtitle, {
      textAlign: "center",
      fontSize: 32,
      [theme.breakpoints.down(screenWidth.mobile)]: {
        textAlign: "left",
      },
    } as CSSProperties),
    description: deepmerge(common.description, {
      textAlign: "center",
      [theme.breakpoints.down(screenWidth.mobile)]: {
        textAlign: "left",
      },
    } as CSSProperties),
    title: deepmerge(common.title, {
      display: "none",
      width: "100%",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        display: "block",
        marginBottom: 40,
        textAlign: "center",
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        display: "block",
        marginBottom: 20,
        textAlign: "left",
      },
    }),
  });
});

const ProductYoutube: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [iframeSize, setIframeSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useIsomorphicLayoutEffect(() => {
    const resizeHandler = () => {
      const MAX_WIDTH = 1100;
      const width = Math.min(window.innerWidth, MAX_WIDTH);
      setIframeSize({
        width,
        height: (width * 9) / 16,
      });
    };
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <div style={{ width: iframeSize.width, height: iframeSize.height }}>
          <YouTube
            videoId="IKwq5wz5zAk"
            opts={{
              width: iframeSize.width.toString(),
              height: iframeSize.height.toString(),
              playerVars: {
                autoplay: 1,
                controls: 0,
                rel: 0,
                showinfo: 0,
                mute: 1,
                loop: 1,
                modestbranding: 1,
                playlist: "IKwq5wz5zAk",
              },
            }}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 24,
            }}
          />
        </div>
        <div className={classes.content}>
          <AnimatedText className={classes.title}>
            {intl.formatMessage({ id: "business_AZAR" })}
          </AnimatedText>
          <AnimatedText className={classes.subtitle} delay={200}>
            {intl.formatMessage({ id: "business_short_description" })}
          </AnimatedText>
          <AnimatedText className={classes.description} delay={400}>
            {intl.formatMessage({ id: "business_long_description" })}
          </AnimatedText>
        </div>
      </div>
    </div>
  );
};

export default ProductYoutube;
