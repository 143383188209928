import React, { CSSProperties, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
// import { useTranslation } from 'react-i18next';
import AnimatedText from "../AnimatedText";
import { useIntl } from "gatsby-plugin-intl";
import { commonStyles, getCurrentFont } from "./styles";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import deepmerge from "deepmerge";

const useStyles = makeStyles<Theme>(theme => {
  const common = commonStyles(theme);
  const currentFont = getCurrentFont();
  return createStyles({
    body: deepmerge(common.body, {
      paddingTop: 140,
      paddingBottom: 126,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        paddingTop: 100,
        paddingBottom: 40,
      },
    }),
    container: deepmerge(common.container, {
      padding: "0px 40px",
    }),
    title: deepmerge(common.title, {
      marginTop: 0,
      marginBottom: 140,
      textAlign: "center",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        width: "100%",
        marginBottom: 60,
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        textAlign: "left",
      },
    } as CSSProperties),
    contentsWrap: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "100px",
      width: "100%",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        gap: "60px",
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        flexDirection: "column",
      },
    },
    content: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flex: "1 1 calc(50% - 100px)",
      "&:before": {
        content: "''",
        height: 2,
        width: "100%",
        backgroundColor: "#888888",
        borderRadius: 2,
        flex: "none",
      },
      paddingTop: 20, // 가상 요소를 위한 여백
    },
    contentTitle: deepmerge(common.title, {
      margin: "20px 0px",
      fontSize: 44,
      fontStyle: "normal",
      fontWeight: 200,
      fontFamily: [currentFont, "sans-serif"].join(","),
      [theme.breakpoints.down(screenWidth.tablet)]: {
        margin: "12px 0px",
      },
    }),
    contentDescription: deepmerge(common.description, {
      marginBottom: 0,
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 300,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        marginBottom: 0,
        fontSize: 14,
      },
    }),
  });
});

const DifferenceContents = [
  "main_difference_subtitle",
  "main_difference_description",
];

const ProductAzarDifference = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <AnimatedText className={classes.title}>
          {intl.formatMessage({ id: "main_difference_title" })}
        </AnimatedText>
        <div className={classes.contentsWrap}>
          {Array(4)
            .fill(DifferenceContents)
            .map((item, idx) => (
              <div key={idx} className={classes.content}>
                <AnimatedText className={classes.contentTitle}>
                  {intl.formatMessage({ id: `${item[0]}${idx + 1}` })}
                </AnimatedText>
                <AnimatedText className={classes.contentDescription}>
                  {intl.formatMessage({ id: `${item[1]}${idx + 1}` })}
                </AnimatedText>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductAzarDifference;
