import agent from "@egjs/agent";

export function formatDate(date: string | Date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

import { v4 as uuidv4 } from "uuid";
type Lang = "en" | "ko";

export const getLanguage = (): Lang | null => {
  if (typeof window === "undefined" || !window.localStorage) return null;
  const lang = localStorage.getItem("gatsby-intl-language");
  return (lang as Lang) || null;
};

export const getIsEnUSBrowser = (): boolean | null => {
  if (typeof window === "undefined" || !window.navigator) return null;
  const browserLanguage = navigator.language;
  return browserLanguage === "en-US";
};

export const getDeviceId = () => {
  const deviceId = localStorage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }
  const newUuid = uuidv4();
  localStorage.setItem("deviceId", newUuid);
  return newUuid;
};

interface AgentVersionInfo {
  name: string;
  version: string;
  majorVersion: number;
}

interface AgentOSInfo extends AgentVersionInfo {}
interface AgentBrowserInfo extends AgentVersionInfo {
  webkit: boolean;
  webkitVersion: string;
  chromium: boolean;
  chromiumVersion: string;
  webview: boolean;
}

interface AgentInfo {
  browser: AgentBrowserInfo;
  os: AgentOSInfo;
  isMobile: boolean;
  isHints: boolean;
}

let agentInfo: AgentInfo | null = null;

export const getDeviceInfo = () => {
  if (agentInfo == null && typeof window !== "undefined") {
    agentInfo = agent(window.navigator.userAgent);
  }
  return {
    browserName: agentInfo?.browser.name,
    browserVersion: agentInfo?.browser.version,
    isWebKit: agentInfo?.browser.webkit,
    os: agentInfo?.os.name,
    osMajorVersion: agentInfo?.os.majorVersion,
    isMobile: agentInfo?.isMobile,
  };
};

export const getLoginParam = () => {
  const deviceInfo = getDeviceInfo();
  const model = `${deviceInfo.browserName}/${deviceInfo.browserVersion}/${deviceInfo.os}`;
  const flavor = deviceInfo.isMobile ? "mobile" : "desktop";
  return {
    deviceId: getDeviceId(),
    versionCode: 9999,
    clientProperties: {
      platform: "WEBCLIENT",
      apiLevel: 9999,
      model,
      flavor,
    },
    clientSideUserSettings: { screenshotEnabled: false },
  };
};

export function isObjectKeyTrue<T>(obj: T, key: keyof T) {
  const value = obj[key];
  if (typeof value !== "string") return !!value;
  return value.toLowerCase() === "true";
}
