import React from "react";
import {
  makeStyles,
  Theme,
  Button as MuiButton,
  ThemeProvider,
} from "@material-ui/core";
import theme from "./theme";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary" | "default" | "inherit";
  variant?: "contained" | "outlined" | "text";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    borderRadius: 28, // 기본 스타일로 반경을 설정합니다.
    padding: "6px 16px",
    fontSize: "15px",
    fontWeight: 700,
    height: "52px",
  },
}));

const Button: React.FC<ButtonProps> = ({
  color = "primary",
  variant = "contained",
  disabled = false,
  onClick,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        classes={{ root: classes.root }}
        color={color}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        disableElevation
        {...props}
      >
        {children}
      </MuiButton>
    </ThemeProvider>
  );
};

export default Button;
