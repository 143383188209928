import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface GapProps {
  width: number;
  mobileWidth?: number;
  tabletWidth?: number;
}

const useStyles = makeStyles<Theme, GapProps>((theme: Theme) =>
  createStyles({
    gap: {
      flexShrink: 0,
      width: (props) => props.width,
      [theme.breakpoints.down('xs')]: {
        width: (props) =>
          props.mobileWidth !== undefined ? props.mobileWidth : props.width,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: (props) =>
          props.tabletWidth !== undefined ? props.tabletWidth : props.width,
      },
    },
  })
);

const Gap: React.FC<GapProps> = (props) => {
  const classes = useStyles(props);
  return <div className={classes.gap} />;
};

export default Gap;