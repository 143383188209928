import { Theme } from "@material-ui/core/styles";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import { getLanguage } from "../../utils";

export const AzarFontMap: Record<"ko" | "en", string> = {
  ko: "Pretendard",
  en: "Poppins",
};

export const getCurrentFont = () => {
  const language = getLanguage();
  return language ? AzarFontMap[language as "ko" | "en"] : "system-ui";
};

export const commonStyles = (theme: Theme) => {
  const currentFont = getCurrentFont();

  return {
    containerBase: {
      maxWidth: screenWidth.productYoutubeMinSize,
      width: "100%",
      paddingLeft: 24,
      paddingRight: 24,
      boxXizing: "border-box",
      marginLeft: "auto",
      marginRight: "auto",
    },
    container: {
      maxWidth: screenWidth.productYoutubeMinSize,
      width: "100%",
      paddingLeft: 24,
      paddingRight: 24,
      boxXizing: "border-box",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        padding: "0px 40px",
      },
    },
    title: {
      fontWeight: "bold" as const,
      fontSize: 82,
      lineHeight: 1,
      marginBottom: 24,
      color: theme.palette.common.black,
      fontFamily: ["Poppins", "system-ui", "sans-serif"].join(","),
      [theme.breakpoints.down(screenWidth.tablet)]: {
        marginBottom: 20,
        fontSize: 38,
      },
    },
    subtitle: {
      fontWeight: 200,
      fontSize: 44,
      lineHeight: 1.5,
      marginBottom: 32,
      color: theme.palette.common.black,
      fontFamily: [currentFont, "sans-serif"].join(","),
      [theme.breakpoints.down(screenWidth.tablet)]: {
        marginBottom: 16,
        fontSize: 20,
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 20,
      lineHeight: 1.5,
      color: "#888888",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        marginBottom: 14,
      },
    },
    body: {
      position: "relative" as const,
      paddingTop: 140,
      paddingBottom: 140,
      boxSizing: "border-box" as const,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: [currentFont, "sans-serif"].join(","),
      overflow: "hidden",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        paddingTop: 60,
        paddingBottom: 60,
        justifyContent: "flex-start",
        flexDirection: "column",
        minHeight: 0,
      },
    },
    buttonWrap: {
      display: "flex",
      paddingTop: 32,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        paddingTop: 20,
      },
      "& button": {
        width: "auto",
      },
    },
  };
};
