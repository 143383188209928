import React, { useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import lottie from "lottie-web";
import AnimatedText from "../AnimatedText";
import Gap from "../Gap";
import useCssMediaDevice, { screenWidth } from "../../hooks/useCssMediaDevice";
import useScrollPercent from "../../hooks/useScrollPercent";
import { AppleStore, PlayStore } from "../icons";
import { commonStyles, getCurrentFont } from "./styles";

interface StyleProps {
  opacity: number;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => {
  const common = commonStyles(theme);
  const currentFont = getCurrentFont();
  return createStyles({
    body: {
      display: "flex",
      opacity: props => props.opacity,
      transition: "opacity 0.1s",
      width: "100%",
      height: "100vh",
      fontFamily: [currentFont, "sans-serif"].join(","),
    },
    container: {
      ...common.containerBase,
      position: "fixed",
      display: "flex",
      left: "50%",
      transform: "translateX(-50%)",
      height: "100vh",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    content: {
      position: "relative",
      width: "100%",
      maxWidth: 616,
      textAlign: "center",
    },
    face: {
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    typo: {
      marginTop: 50,
      maxWidth: "100%",
      fontWeight: 300,
      fontSize: 42,
      lineHeight: 1.5,
      whiteSpace: "nowrap",
      color: "black",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        marginTop: 24,
        fontSize: 19,
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        fontSize: 16,
      },
    },
    storeWrap: {
      position: "absolute",
      bottom: 100,
    },
    storeIcons: {
      display: "flex",
      opacity: props => props.opacity,
    },
    brandContainer: {
      display: "flex",
      padding: "8px 12px",
      alignItems: "center",
      backgroundColor: "#F8F8F8",
      borderRadius: 8,
      color: "#666666",
      fontSize: 14,
      [theme.breakpoints.down(screenWidth.mobile)]: {
        borderRadius: 100,
        padding: 8,
      },
    },
    icBrand: {
      width: 20,
      height: 20,
      color: "#666666",
    },
    link: {
      textDecoration: "none",
    },
  });
});

const AnimState = {
  PENDED: "PENDED",
  PLAYING: "PLAYING",
};

const Hero = () => {
  const bodyRef = useRef(null);
  const faceRef = useRef(null);
  const [animState, setAnimState] = useState(AnimState.PENDED);
  const device = useCssMediaDevice();
  const isMobile = device === "mobile";
  const opacity = useScrollPercent(bodyRef);
  const classes = useStyles({ opacity });

  useEffect(() => {
    const container = faceRef.current;
    if (container && typeof window !== "undefined") {
      axios.get(`/lottie/intro.json`).then(({ data: animationData }) => {
        const anim = lottie.loadAnimation({
          container: (container as unknown) as Element, // the DOM element that will contain the animation
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData,
        });
        anim.addEventListener("DOMLoaded", () => {
          setAnimState(AnimState.PLAYING);
        });
      });
    }
  }, []);

  return (
    <div ref={bodyRef} className={classes.body}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div ref={faceRef} className={classes.face} />
          {animState === AnimState.PLAYING && (
            <AnimatedText delay={1000}>
              <div className={classes.typo}>
                Connection is just one chat away
              </div>
            </AnimatedText>
          )}
        </div>
        <div className={classes.storeWrap}>
          <AnimatedText delay={700}>
            <div className={classes.storeIcons}>
              <a
                href="https://app.adjust.com/rqafhu6"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <div className={classes.brandContainer}>
                  <AppleStore className={classes.icBrand} />
                  {!isMobile && (
                    <>
                      <Gap width={4} />
                      <span>App Store</span>
                    </>
                  )}
                </div>
              </a>
              <Gap width={8} />
              <a
                href="https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=about"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <div className={classes.brandContainer}>
                  <PlayStore className={classes.icBrand} />
                  {!isMobile && (
                    <>
                      <Gap width={4} />
                      <span>Google Play</span>
                    </>
                  )}
                </div>
              </a>
            </div>
          </AnimatedText>
        </div>
      </div>
    </div>
  );
};

export default Hero;
