import { RefObject, useEffect, useState } from "react";

const useScrollPercent = (
  ref: RefObject<HTMLElement>,
  forcePositive?: boolean
) => {
  const [percent, setPercent] = useState(1);
  useEffect(() => {
    const updateScrollPercentage = () => {
      if (!ref.current) {
        return;
      }
      const { top, height } = ref.current.getBoundingClientRect();
      if (top > window.innerHeight) {
        setPercent(0);
        return;
      }

      if (-top > height) {
        setPercent(0);
        return;
      }
      if (!forcePositive && top < 0) {
        setPercent(1 - -top / height);
        return 0;
      }
      // setPercent((window.innerHeight - top) / height);
    };
    window.addEventListener("scroll", updateScrollPercentage);
    return () => {
      window.removeEventListener("scroll", updateScrollPercentage);
    };
  }, [forcePositive, ref]);
  return percent;
};

export default useScrollPercent;
