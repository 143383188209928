import React, { CSSProperties, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link, useIntl } from "gatsby-plugin-intl";
import useIntersection from "../../hooks/useIntersection";
import { commonStyles } from "./styles";
import Button from "./Button";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import AnimatedText from "../AnimatedText";
import deepmerge from "deepmerge";

const useStyles = makeStyles<Theme, { isIntersecting: boolean }>(theme => {
  const common = commonStyles(theme);
  return {
    "@global": {
      "@keyframes slide": {
        from: {
          right: 0,
        },
        to: {
          right: "calc(-1828px + 100%)",
        },
      },
    },
    body: common.body,
    container: deepmerge(common.container, {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        flexDirection: "column-reverse",
      },
    } as CSSProperties),
    slider: {
      marginTop: 100,
      marginLeft: -18,
      height: "100%",
      maxHeight: 562,
      minHeight: 562,
      width: "100%",
      position: "relative",

      [theme.breakpoints.down(screenWidth.tablet)]: {
        height: 548,
        marginTop: 0,
        marginBottom: 60,
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        marginBottom: 40,
      },
    },
    sliderContent: (props: { isIntersecting: boolean }) => ({
      position: "absolute",
      width: 1828,
      height: 562,
      top: 0,
      right: 0,

      maxWidth: "none",
      animation: props.isIntersecting
        ? `slide 5s linear forwards 0.5s`
        : "none",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        height: 548,
      },
    }),
    title: common.title,
    subtitle: common.subtitle,
    description: common.description,
    buttonWrap: common.buttonWrap,
  };
});

const Live: React.FC = () => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const handleIntersect: IntersectionObserverCallback = ([entry]) => {
    if (isIntersecting !== entry.isIntersecting) {
      setIsIntersecting(entry.isIntersecting);
    }
  };
  const { observerRef } = useIntersection(handleIntersect);
  const classes = useStyles({ isIntersecting });
  const intl = useIntl();
  return (
    <div className={classes.body}>
      <div className={classes.container} ref={observerRef}>
        <div className={classes.content}>
          <AnimatedText className={classes.title}>
            {intl.formatMessage({ id: "live" })}
          </AnimatedText>
          <AnimatedText delay={200} className={classes.subtitle}>
            {intl.formatMessage({ id: "live_short_description" })}
          </AnimatedText>
          <AnimatedText delay={400} className={classes.description}>
            {intl.formatMessage({ id: "live_long_description" })}
          </AnimatedText>
          <AnimatedText delay={600} className={classes.buttonWrap}>
            <a href="https://azarlive.com/" target="_blank" rel="noreferrer">
              <Button color="secondary" id="ABOUT_LIVE">
                {intl.formatMessage({ id: "product_live" })}
              </Button>
            </a>
          </AnimatedText>
        </div>
        <div className={classes.slider}>
          <img
            src="/product/live@3x.png"
            alt="Azarlive live slider image"
            className={classes.sliderContent}
            width={1828}
            height={562}
          />
        </div>
      </div>
    </div>
  );
};

export default Live;
