import React, { FC, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import useIntersection from "../../hooks/useIntersection";

export interface AnimatedTextProps {
  text?: string;
  isSlide?: boolean;
  isFade?: boolean;
  isGrow?: boolean;
  isShow?: boolean;
  className?: string;
  direction?: "up" | "down" | "left" | "right";
  unit?: string;
  delay?: number;
  children?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  base: (props: AnimatedTextProps) => ({
    position: "relative",
    overflow: props.isSlide ? "visible" : props.isFade ? "visible" : "hidden",
    display: "inline-block",
    whiteSpace: "pre-line",
    wordBreak: "keep-all",
  }),
  word: (props: AnimatedTextProps) => {
    let transform = "";
    if (props.isSlide) {
      switch (props.direction) {
        case "left":
          transform += "translateX(50px) ";
          break;
        case "right":
          transform += "translateX(-50px) ";
          break;
        case "down":
          transform += "translateY(-50px) ";
          break;
        case "up":
          transform += "translateY(50px) ";
          break;
        default:
          break;
      }
    }
    if (props.isGrow) {
      transform += "scale(0.7) ";
    }
    return {
      display: "inline-block",
      transitionProperty: "transform, opacity",
      transitionTimingFunction: props.isFade
        ? "cubic-bezier(0.19, 1, 0.22, 1), cubic-bezier(0.19, 1, 0.22, 1)"
        : "cubic-bezier(.075,.82,.165,1)",
      transform: transform.trim() || "none",
      opacity: props.isFade ? 0 : 1,
      transitionDuration:
        props.isFade && props.isSlide
          ? "2s, 1s"
          : props.isFade
          ? "1.3s"
          : ".8s",
      "&.show": {
        transitionDelay: props.isShow ? `${props.delay}ms` : "0ms",
        transform: "translate(0, 0) scale(1)",
        opacity: 1,
      },
    };
  },
}));

const AnimatedText: FC<AnimatedTextProps> = ({
  text,
  isSlide = true,
  isFade = true,
  isGrow = false,
  direction = "down",
  className,
  unit = "line",
  isShow = true,
  delay = 0,
  children,
}) => {
  const baseProps = {
    isSlide,
    isFade,
    direction,
  };
  const wordProps = {
    delay,
    isFade,
    isSlide,
    isGrow,
    isShow,
    direction,
  };

  const classes = useStyles({ ...baseProps, ...wordProps });

  const [isVisible, setIsVisible] = useState(false);

  const handleIntersect = useCallback<IntersectionObserverCallback>(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    },
    []
  );

  const { observerRef } = useIntersection(handleIntersect);

  return (
    <div className={className} ref={observerRef}>
      {text ? (
        text.split("\\n").map((line, i) => (
          <div key={`animated-text-${line}-${i}`}>
            {unit === "word" ? (
              line.split(" ").map((word, j) => {
                const wordDelay = delay + (i + j) * 100;
                return (
                  <div
                    key={`animated-text-${line}-${i}-${word}-${j}`}
                    className={classes.base}
                  >
                    <div
                      className={clsx(
                        classes.word,
                        "animated-word",
                        isVisible && "show"
                      )}
                      style={{ transitionDelay: `${wordDelay}ms` }}
                    >
                      {`${word} `}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classes.base}>
                <div
                  className={clsx(
                    classes.word,
                    "animated-word",
                    isVisible && "show"
                  )}
                  style={{ transitionDelay: `${delay}ms` }}
                >
                  {`${line}`}
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={classes.base}>
          <div
            className={clsx(classes.word, "animated-word", isVisible && "show")}
            style={{ transitionDelay: `${delay}ms` }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnimatedText;
