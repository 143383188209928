// theme.js 또는 theme.ts 파일 생성
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#1CD092", // 원하는 secondary 색상으로 변경하세요
    },
  },
});

export default theme;
