import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import styled from "@material-ui/core/styles/styled";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Hero from "../../components/Product/Hero";
import ProductYoutube from "../../components/Product/ProductYoutube";
import ProductAzarDifference from "../../components/Product/ProductAzarDifference";
import Match from "../../components/Product/Match";
import Live from "../../components/Product/Live";
import Lounge from "../../components/Product/Lounge";
import { getIsEnUSBrowser } from "../../utils";
import { v4 as uuidv4 } from "uuid";
// import {
//   useEnableVisitorConfig,
//   useGetVisitorConfigs,
// } from "../../hooks/useVisitor";

const RootStyle = styled("div")({
  width: "100%",
  color: "white",
});
const Container = styled("div")({
  display: "flex",
  height: "100vh",
});

interface BusinessPageProps {
  pageContext: {
    language: string;
  };
}

function BusinessPage({ pageContext }: BusinessPageProps) {
  const intl = useIntl();
  const { language } = pageContext;

  const isEnUSBrowser = getIsEnUSBrowser();

  // TODO: 미국의 geolocation을 캐치하는 로직은 보다 고도화되어야 함
  // TODO: (ex. 아래는 Azar live의 코드를 가져온 부분이지만 azar api를 사용하는 것에서 따로 확인을 받아야 사용할 수 있을 듯 함)
  // useGetVisitorConfigs();
  // const isLiveOff = useEnableVisitorConfig("LiveTabDisabled");
  // const isLoungeOff = useEnableVisitorConfig("LoungeDisabled");
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null; // 또는 로딩 스피너 등을 반환
  }

  return (
    <Layout language={language}>
      <RootStyle>
        <SEO
          title="Product"
          description={intl.formatMessage({ id: "business_description" })}
        />
        <Container>
          <Hero key={uuidv4()} />
        </Container>
        <ProductYoutube />
        <ProductAzarDifference />
        <Match />
        {!isEnUSBrowser && <Live />}
        {!isEnUSBrowser && <Lounge />}
      </RootStyle>
    </Layout>
  );
}

export default BusinessPage;
