import React, { CSSProperties, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useIntl } from "gatsby-plugin-intl";
import useIntersection from "../../hooks/useIntersection";
import AnimatedText from "../AnimatedText";
import { createStyles } from "@material-ui/core";
import { commonStyles } from "./styles";
import Button from "./Button";
import { screenWidth } from "../../hooks/useCssMediaDevice";
import deepmerge from "deepmerge";

const MAX_WIDTH = 375;
const MAX_HEIGHT = 734;
const PADDING_SIZE = 80;
const ASPECT_RATIO = MAX_HEIGHT / MAX_WIDTH;
const BASE_RADIUS = 24;

const useStyles = makeStyles<Theme, { windowWidth: number }>(theme => {
  const common = commonStyles(theme);
  return createStyles({
    body: common.body,
    container: deepmerge(common.container, {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        justifyContent: "flex-start",
        alignItems: "flex-end",
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        fontSize: 38,
      },
    } as CSSProperties),
    content: {
      width: 625,
      [theme.breakpoints.down(screenWidth.tablet)]: {
        width: "auto",
        textAlign: "left",
      },
      [theme.breakpoints.down(screenWidth.mobile)]: {
        paddingLeft: 0,
        marginTop: 40,
      },
    },
    deck: {
      position: "relative",
      marginRight: 60,
      width: 375,
      height: 734,
      overflow: "hidden",
      borderRadius: 24,
      backgroundColor: "white",
      [theme.breakpoints.down(screenWidth.tablet)]: {
        maxWidth: 280,
        height: 548,
        borderRadius: 17.92,
      },
      [theme.breakpoints.down(screenWidth.xs)]: {
        minWidth: 0,
        maxWidth: "100%",
        width: "calc(100vw - 80px)",
        height: `calc((100vw - ${PADDING_SIZE}px) * ${ASPECT_RATIO})`,
        marginRight: 0,
        marginBottom: 36,
        borderRadius: `calc(((100vw - ${PADDING_SIZE}px) / ${MAX_WIDTH}) * ${BASE_RADIUS})`,
      },
    },

    loungeImage: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
      transformOrigin: "50% 100%",
      transform: "translate(0, 0)",
      transition: "transform 3s ease-in-out 0.3s",
    },
    loungeImageAnimated: props => ({
      transform: `translate(0, calc(-50% + ${
        props.windowWidth > 960 ? 40 : 30
      }px))`,
    }),
    title: common.title,
    subtitle: common.subtitle,
    description: common.description,
    buttonWrap: common.buttonWrap,
  });
});

const Lounge: React.FC = () => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const isClient = typeof window === "object";
  const [windowWidth, setWindowWidth] = useState(
    isClient ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isIntersecting, setIsIntersecting] = useState(false);
  const handleIntersect: IntersectionObserverCallback = ([entry]) => {
    setIsIntersecting(entry.isIntersecting);
  };
  const { observerRef } = useIntersection(handleIntersect);
  const classes = useStyles({ windowWidth });

  return (
    <div className={classes.body}>
      <div className={classes.container} ref={observerRef}>
        <div className={classes.deck}>
          <img
            src="/product/lounge-image@3x.png"
            alt="Azarlive lounge image"
            className={`${classes.loungeImage} ${
              isIntersecting ? classes.loungeImageAnimated : ""
            }`}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.title}>{t({ id: "lounge" })}</div>
          <AnimatedText className={classes.subtitle} delay={200}>
            {t({ id: "lounge_short_description" })}
          </AnimatedText>
          <AnimatedText className={classes.description} delay={400}>
            {t({ id: "lounge_long_description" })}
          </AnimatedText>
          <AnimatedText className={classes.buttonWrap} delay={200}>
            <a
              href="https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=putter_ios"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button id="ABOUT_INSTALL_APP" color="secondary">
                {t({ id: "product_install_app" })}
              </Button>
            </a>
          </AnimatedText>
        </div>
      </div>
    </div>
  );
};

export default Lounge;
